import React, { useEffect, useState } from 'react';
import './Leaderboard.css'; // Custom styles

const API_URL = process.env.REACT_APP_API_URL;

function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState([]);
  const [userSubmissions, setUserSubmissions] = useState({}); // To store totalSubmissions by userId
  const TOTAL_PLAYERS = 11; // Constant for total players in the lineup

  useEffect(() => {
    // Fetch the leaderboard data
    fetch(`${API_URL}/api/leaderboard`)
      .then((res) => res.json())
      .then((data) => {
        console.log('Leaderboard Data:', data);
        // Store only the top 10 users in the leaderboard
        setLeaderboard((data.topUsers || []).slice(0, 10));
      })
      .catch((error) => {
        console.error('Error fetching leaderboard:', error);
        setLeaderboard([]); // Default to empty array on error
      });

    // Fetch the total submissions data
    fetch(`${API_URL}/api/lineups/user-count`)
      .then((res) => res.json())
      .then((data) => {
        console.log('Total Submissions Data:', data);
        const submissionsMap = data.reduce((acc, item) => {
          acc[item.userId] = item.totalSubmissions;
          return acc;
        }, {});
        setUserSubmissions(submissionsMap); // Map userId to totalSubmissions
      })
      .catch((error) => {
        console.error('Error fetching total submissions:', error);
        setUserSubmissions({});
      });
  }, []);

  return (
    <div className="leaderboard-section">
      <h2>Βαθμολογία</h2>
      {leaderboard.length === 0 ? (
        <p>No users on the leaderboard.</p>
      ) : (
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Θεση</th>
              <th>Χρηστης</th>
              <th>Μ.Ο. ΠΟΝΤΩΝ ΑΝΑ ΠΑΙΧΝΙΔΙ</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((user, index) => {
              const totalSubmissions = userSubmissions[user._id] || 1; // Default to 1 to avoid division by zero

              const averagePoints = (user.points / totalSubmissions).toFixed(2); // Original with total submissions
              

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.username}</td>
                  <td>{`${averagePoints}/${TOTAL_PLAYERS} έχει ψηφίσει ${totalSubmissions} φορές`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Leaderboard;
